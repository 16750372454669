import Button from '@components/Button';
import { useCurrentBreakpoint } from '@helpers/currentBreakpoint';
import loadable from '@loadable/component';
import FluidImage from '@utility/FluidImage';
import { graphql, useStaticQuery } from 'gatsby';
import noScroll from 'no-scroll';
import PropTypes from 'prop-types';
import React, { useLayoutEffect, useMemo, useState } from 'react';
import { Portal } from 'react-portal';

const FinishModal = loadable(() => import('@components/FinishModal'));

const FinishButton = ({
  possibleFinishes,
  optionIndex,
  lang,
  optionName,
  modalState,
  scrollToState,
  productFinishes,
}) => {
  const [finishViewer, setFinishViewer] = useState(
    modalState?.index === optionIndex ?? false,
  );
  const [offset, setOffset] = useState(scrollToState ?? 0);

  const currentBreakpoint = useCurrentBreakpoint();

  let activeFinishCode = null;
  let matchingFinish = null;

  if (possibleFinishes) {
    activeFinishCode = Object.entries(possibleFinishes).find(
      (el) => el[1] === 2,
    );
  }

  if (activeFinishCode) {
    matchingFinish = productFinishes.find((el) => {
      return el.finish.code === activeFinishCode[0];
    });
  }

  useLayoutEffect(() => {
    if (['md', 'lg', 'xl'].includes(currentBreakpoint)) {
      const stickyElements = document.querySelectorAll('.top-correction');

      const bodyMarginTop = +getComputedStyle(document.body).marginTop.slice(
        0,
        -2,
      );
      const stickyOffset = `${
        +document.documentElement.style.top.slice(1, -2) - bodyMarginTop
      }px`;

      if (stickyElements?.length) {
        stickyElements.forEach((el) => {
          if (finishViewer) {
            el.style.top = stickyOffset;
          } else {
            stickyElements.forEach((el) => {
              el.style.top = '';
            });
          }
        });
      }
    }
  }, [finishViewer, currentBreakpoint]);

  return matchingFinish ? (
    <>
      <Button
        className="relative btn btn-config btn-config--finish print:hidden"
        aria-label={`Current Selection: ${matchingFinish.finish.title} - Open finish picker modal`}
        onClick={() => {
          setOffset(
            window.pageYOffset +
              +document.documentElement.style.top.slice(1, -2) -
              +getComputedStyle(document.body).marginTop.slice(0, -2),
          );
          noScroll.on();
          setFinishViewer(!finishViewer);
        }}
      >
        {matchingFinish && (
          <FluidImage
            className="w-6 h-6 rounded-full mr-4 isolate"
            src={matchingFinish.finish.image}
            maxWidth={50}
            maxHeight={50}
          />
        )}
        {matchingFinish.finish.title}
        <span className="hidden print:block absolute right-0 top-0 px-2">
          {activeFinishCode?.[0]}
        </span>
      </Button>

      <Portal>
        <FinishModal
          open={finishViewer}
          activeFinishCode={activeFinishCode[0]}
          productFinishes={productFinishes}
          modalContainerScroll={modalState?.modalContainerScroll}
          offset={offset}
          optionIndex={optionIndex}
          optionName={optionName}
          possibleFinishes={possibleFinishes}
          closeModal={() => {
            noScroll.off();
            setFinishViewer(!finishViewer);
          }}
        />
      </Portal>
    </>
  ) : (
    <div
      className="relative btn btn-config btn-config--selected print:hidden"
      type="button"
      disabled
    >
      {activeFinishCode && lang[activeFinishCode[0]]}
      <span className="hidden print:block absolute right-0 top-0 px-2">
        {activeFinishCode?.[0]}
      </span>
    </div>
  );
};
export default FinishButton;

FinishButton.propTypes = {
  possibleFinishes: PropTypes.objectOf(PropTypes.number).isRequired,
  optionIndex: PropTypes.number.isRequired,
  lang: PropTypes.objectOf(PropTypes.string).isRequired,
  optionName: PropTypes.string.isRequired,
  modalState: PropTypes.shape({
    open: PropTypes.bool,
    index: PropTypes.number,
  }),
  productFinishes: PropTypes.array.isRequired,
};

FinishButton.defaultProps = {
  modalState: null,
};
