import ImageFullWidth from '@components/ImageFullWidth';
import FluidImage from '@src/utility/FluidImage';
import ScrollInView from '@utility/ScrollInView/index';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import SwiperCore, {
  EffectFade,
  Keyboard,
  Navigation,
  Pagination,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as styles from './styles';
import 'swiper/css/effect-fade';
import PortableText from '../PortableText';

SwiperCore.use([Keyboard, Navigation, Pagination, EffectFade]);

const ProductImagesCarousel = ({
  images,
  alt,
  onLoad,
  configuratorTextOverlapIndex,
  customPrice,
  configurationReminder,
}) => {
  const ref = useRef(null);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const params = {
    slidesPerView: 1,
    speed: 1000,
    keyboard: {
      enabled: true,
    },
    loop: true,
    simulateTouch: false,
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    watchSlidesProgress: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
  };

  // Add eventlisteners for swiper after initializing
  useEffect(() => {
    if (!!swiperInstance && typeof window !== 'undefined') {
      swiperInstance.on('resize', () => {
        swiperInstance.update();
      });
    }

    return () => {
      if (ref.current?.swiper) {
        swiperInstance.off('progress');
        swiperInstance.off('resize');
        swiperInstance.off('update');
        swiperInstance.off('touchStart');
        swiperInstance.off('setTransition');
      }
    };
  }, [swiperInstance]);

  return (
    <>
      <div className={styles.productImageCarousel}>
        <Swiper
          {...params}
          onSwiper={(instance) => {
            setSwiperInstance(instance);
            onLoad();
          }}
          onSlideChange={(swiper) => {
            setCurrentSlide(swiper.realIndex);
          }}
        >
          {images &&
            images.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="js-swiper-slide-inner slide-inner">
                  {typeof image !== 'string' ? (
                    <FluidImage
                      src={image}
                      loading="eager"
                      className={cn('h-full w-full relative')}
                      objectFit="cover"
                    />
                  ) : (
                    <>
                      <img
                        className={cn('object-contain h-full w-full relative')}
                        src={image}
                        alt={alt}
                        height="auto"
                        width="100%"
                      />
                      {customPrice && (
                        <div className={styles.customWrapper}>
                          <span>Contact sales for custom finish</span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </SwiperSlide>
            ))}
          <div className="swiper-prev" data-context="Previous" />
          <div className="swiper-next" data-context="Next" />
          <div className="swiper-pagination"></div>
        </Swiper>
      </div>
      {configurationReminder && (
        <div className={`${styles.configurationReminderWrapper} ${currentSlide < configuratorTextOverlapIndex  ? 'opacity-100' : 'opacity-0'} hidden md:block`}>
          <PortableText
            className={styles.configurationReminder}
            content={configurationReminder}
          />
        </div>
      )}
    </>
  );
};

ProductImagesCarousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ).isRequired,
  alt: PropTypes.string,
  onLoad: PropTypes.func,
  customPrice: PropTypes.bool,
  configuratorTextOverlapIndex: PropTypes.number,
  configurationReminder: PropTypes.object,
};

export default ProductImagesCarousel;
