import React, { useEffect, useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import TextArea from '@utility/TextArea';
import StringItem from '@components/StringItem';
import BulletList from '@components/BulletList';
import { certificationListSrc } from '../../../../shared/certificationList';
import ProductCode from '@components/ProductCode';
import Logo from '@components/Logo';

const ProductPrint = ({
  children,
  sanityProduct: {
    dimensions,
    materials,
    technicalDrawing,
    modification,
    description,
    certificationList,
    productWeight,
    performance,
    photometrySummary,
    ledLifespan,
    patentNumber,
    suspension,
  },
  productCode,
  blankProductCode,
  title,
  productType,
}) => {
  const ceIndex = certificationList.findIndex((e) => e === 'ce');
  if (ceIndex !== -1) {
    certificationList.splice(ceIndex, 1);
    certificationList.unshift('ce');
  }

  const containsUlUs = certificationList.indexOf('c_ul_us') !== -1;

  const performanceList = photometrySummary ? [...performance, `Full detail in <a href=${photometrySummary} target='_self'>photometry summary</a>[showPrint]`] : performance;

  return (
    <StaticQuery
      query={graphql`
        query TearSheetCoverQuery {
          allSanityConfigurator {
            edges {
              node {
                tearSheetContact
                tearSheetFootnote
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <>
            <div
              className="hidden print:block print:relative overflow-hidden"
              id="print-sheet-wrapper"
              // style={({ height: '11in' }, { maxHeight: '11in' })}
            >
              <div className='absolute right-0'>
                <Logo className="mt-[-10px]" width="50" height="75" />
              </div>
              <div className="grid grid-cols-9 gap-x-12">
                <div className="col-span-3 list-none type-sans-042 !leading-normal">
                  {children.slice(2)}

                  <p className="type-sans-630 mb-2">{title}</p>
                  <p>{productType}</p>

                  <ProductCode
                    productCode={productCode}
                    blankProductCode={blankProductCode}
                    sticky
                  />

                  <h4 className="type-upper-072">Description</h4>
                  <p className="type-sans-042 !leading-normal">{description}</p>

                  <ul>
                    <h4 className="type-upper-072 mt-5">Materials</h4>
                    <StringItem label="" paragraph={materials} />

                    {modification && (
                      <StringItem
                        label="Collection Notes"
                        paragraph={modification}
                      />
                    )}

                    {/* Dimensions */}
                    {dimensions && dimensions !== '' && (
                      <StringItem
                        label="Product Dimensions"
                        paragraph={dimensions}
                      />
                    )}
                    <StringItem
                      label="Product Weight"
                      paragraph={productWeight}
                    />
                  </ul>
                </div>

                <div className="col-span-6">
                  <div className="grid grid-cols-2 gap-x-4">
                    {/* Block 1 */}
                    <ul className="list-none type-sans-042 !leading-normal">
                      <BulletList label="Performance" listing={performanceList} />

                      <StringItem
                        label="Patent File Number"
                        paragraph={patentNumber}
                      />

                      <BulletList label="Suspension" listing={suspension} />
                    </ul>

                    {/* Block 2 */}
                    <ul className="list-none type-sans-042 !leading-normal">
                      {/* Certifications */}
                      {certificationList.length ? (
                        <li className="mb-8 sm:mb-6 avoid-break">
                          <h4 className="type-upper-072">Certification</h4>
                          {certificationList.map((el, index) => {
                            const match = certificationListSrc.find(
                              (src) => src.value === el,
                            );
                            return (
                              match && (
                                <React.Fragment key={el + index}>
                                  <ul>
                                    <li
                                      className={
                                        containsUlUs &&
                                        [
                                          'ul_wet',
                                          'ul_damp',
                                          'ul_dry',
                                        ].includes(el)
                                          ? 'pl-2 !list-disc !list-inside'
                                          : ''
                                      }
                                    >
                                      {match.title}{' '}
                                    </li>
                                  </ul>
                                </React.Fragment>
                              )
                            );
                          })}
                          {certificationList.map((el, index) => {
                            const match = certificationListSrc.find(
                              (src) => src.value === el,
                            );
                            return (
                              match && (
                                <React.Fragment key={el + index}>
                                  <ul>
                                    {match.image && (
                                      <li>
                                        <img
                                          src={`/images/certifications/${match.image}`}
                                          className="w-12"
                                          alt={match.title}
                                        />
                                      </li>
                                    )}
                                  </ul>
                                </React.Fragment>
                              )
                            );
                          })}
                        </li>
                      ) : null}

                      {ledLifespan.length ? (
                        <li className="mb-8 sm:mb-12 print:mb-2 avoid-break">
                          <h4 className="type-upper-072">Led Lifespan</h4>
                          <ul>
                            {ledLifespan.map((el, index) => (
                              <li key={el + index}>{el} </li>
                            ))}
                          </ul>
                        </li>
                      ) : null}

                      {/* <StringItem
                        label="Note on Imagery"
                        paragraph={imageryDisclaimer}
                      /> */}
                    </ul>

                    {/* Specification logic */}
                    <div className="col-span-2">
                      <h4 className="type-upper-070 print:border-b print:border-mono-500 mb-4">
                        Specification logic
                      </h4>
                      {children[0]}
                      {technicalDrawing?.length > 0 && (
                        <div className="mt-[-65px] relative z-10" id="printDrawings">
                          <h4 className="type-upper-070 print:border-b print:border-mono-500 mb-4 ">
                            Drawings
                          </h4>
                          <div
                            key={technicalDrawing[0].asset.url + 0}
                            className="print:break-inside-avoid"
                          >
                            <p className="type-sans-030">
                              {technicalDrawing[0].altText}
                            </p>
                            <img
                              src={technicalDrawing[0].asset.url}
                              alt={technicalDrawing[0].altText}
                              className="w-full"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div>{children[1]}</div>
            </div>

            <div className="hidden print:block fixed bottom-0 flex-col w-full items-baseline z-20">
              {/* <p className="type-sans-040 !text-[0.65rem] w-2/3 print:text-left">
                {data.allSanityConfigurator.edges[0].node.tearSheetFootnote}
              </p> */}
              <p className="type-sans-042 !leading-normal !text-[0.65rem] w-2/3 z-20 print:text-left">
                {data.allSanityConfigurator.edges[0].node.tearSheetContact}
              </p>
            </div>
          </>
        );
      }}
    />
  );
};

export default ProductPrint;
