import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';

const useCustomFinishCheck = (skuSuffix) => {
  const accountData = useStaticQuery(
    graphql`
      query {
        sanityConfigurator {
          customMarking
        }
      }
    `,
  );
  const { customMarking } = accountData.sanityConfigurator;

  // Generate the regex only when customMarking changes
  const customFinishRegex = useMemo(() => {
    const regexp = new RegExp(
        `(${customMarking
          .split(',')
          .map((e) => e.trim())
          .join('|')})`,
        'i'
      );
    return regexp;
  }, [customMarking]);

  // Check if the skuSuffix matches the regex
  const isCustom = useMemo(() => customFinishRegex.test(skuSuffix), [skuSuffix, customFinishRegex]);
  return isCustom;
};

export default useCustomFinishCheck;
