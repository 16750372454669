import React from 'react';

const SkuContext = React.createContext(
  (code, key, offset, modalContainerScroll) => {
    return;
  },
);

export const SkuProvider = SkuContext.Provider;
export default SkuContext;
