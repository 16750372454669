import { useCurrentBreakpoint } from '@src/helpers/currentBreakpoint';
import FluidImage from '@src/utility/FluidImage';
import ScrollInView from '@utility/ScrollInView';
import PropTypes from 'prop-types';
import React from 'react';
import { useMemo } from 'react';
import SwiperCore, { Navigation } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import * as styles from './styles';

SwiperCore.use([Navigation]);

const ImageCenterCarousel = ({ images }) => {
  const currentBreakpoint = useCurrentBreakpoint();

  const params = useMemo(
    () => ({
      slidesPerView: 'auto',
      speed: 600,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        enabled: true,
      },
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      centeredSlides: true,
      loop: true,
      loopedSlides:
        currentBreakpoint === 'xl' || currentBreakpoint === 'lg' ? 3 : 2,
      spaceBetween:
        currentBreakpoint === 'xl' || currentBreakpoint === 'lg' ? 40 : 20,
    }),
    [currentBreakpoint],
  );

  return (
    <div className={styles.imageCenterCarousel}>
      <ScrollInView>
        <Swiper
          {...params}
          onSwiper={(swiper) => {
            setTimeout(() => {
              swiper.update();
              if (swiper.params) {
                swiper.slideToLoop(0, 0);
              }
              swiper.navigation?.update();
            }, 100);
          }}
          onSlideChange={(swiper) => {
            swiper.update();
            swiper.navigation.update();
          }}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <FluidImage
                src={image}
                maxHeight={934}
                aspectRatio={image.asset.metadata.dimensions.aspectRatio}
                className="h-full"
                loading={'eager'}
                style={{
                  objectPosition: `${
                    image.hotspot?.x ? image.hotspot.x * 100 : 50
                  }% center`,
                }}
              />
            </SwiperSlide>
          ))}
          <button className="swiper-button-next" aria-label='Next Button'></button>
          <button className="swiper-button-prev" aria-label='Previous Button'></button>
        </Swiper>
      </ScrollInView>
    </div>
  );
};

ImageCenterCarousel.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      asset: PropTypes.object.isRequired,
      altText: PropTypes.string,
      hotspot: PropTypes.object,
    }),
  ).isRequired,
};

ImageCenterCarousel.defaultProps = {
  data: {
    subtitle: '',
    description: '',
    link: [],
    featuredProducts: [],
  },
  productByCollection: [],
};

export default ImageCenterCarousel;
