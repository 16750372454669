// extracted by mini-css-extract-plugin
export var configImage = "styles-module--configImage--d8d64";
export var configImageInner = "styles-module--configImageInner--38059";
export var configItem = "styles-module--configItem--28790";
export var configSpinner = "styles-module--configSpinner--ad6e2";
export var configTop = "styles-module--configTop--67540";
export var configurationReminder = "styles-module--configurationReminder--d646a";
export var configurationReminderWrapper = "styles-module--configurationReminderWrapper--3612b";
export var customWrapper = "styles-module--customWrapper--cc51f";
export var dateStamp = "styles-module--dateStamp--ee8c9";
export var fadeIn = "styles-module--fadeIn--e3ce4";