import Button from '@components/Button';
import Configurator from '@components/Configurator';
import CreateProject from '@components/CreateProject';
import PortableText from '@components/PortableText';
import Price from '@components/Price';
import PrintHeading from '@components/PrintHeading';
import ProductCarousel from '@components/ProductCarousel';
import ProductDisplay from '@components/ProductDisplay';
import ProductDisplayHeading from '@components/ProductDisplayHeading';
import Quantity from '@components/Quantity';
import SEO from '@components/seo';
import { SkuProvider } from '@components/SkuContext';
import TearSheetCover from '@components/TearSheetCover';
import TearSheetDrawings from '@components/TearSheetDrawings';
import {
  attributesFromSkuSuffix,
  blankSkuSuffixHtml,
  calcLeadTimeByQuantity,
  productImageSrc,
} from '@helpers/product';
import Link from '@utility/Link';
import cn from 'classnames';
import { graphql, navigate } from 'gatsby';
import noScroll from 'no-scroll';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { REGEX_CUSTOM_FINISH_PATTERN } from '../../common';
import * as skuStyles from './skuStyles';

import AuthUserContext from '@context/AuthUserContext';
import Components from '@src/components/Components';
import ImageCenterCarousel from '@src/components/ImageCenterCarousel';
import ProductPrint from '@src/components/ProductPrint';
import useCustomFinishCheck from '@src/utility/useCustomRegexHook';
import { viewItemDL } from '@src/helpers/dataLayerHelper';

export const query = graphql`
  query ($id: String, $skuId: String) {
    sanitySku(_id: { eq: $skuId }) {
      options
      price
      discount_price
      weight
    }
    sanityProduct(id: { eq: $id }) {
      ...ProductDisplayFragment
      metaDescription: description
      shoppable
      partial_sku
      collection {
        slug {
          current
        }
        heroPrimary {
          title
        }
      }
      configurationReminder: _rawConfigurationReminder(
        resolveReferences: { maxDepth: 5 }
      )
      productType {
        slug {
          current
        }
        title
      }
      hasOffImage
      productImagesCarousel {
        asset {
          _id
        }
        altText
      }
      installCarousel {
        asset {
          _id
          metadata {
            dimensions {
              aspectRatio
            }
          }
        }
        hotspot {
          x
          y
        }
        altText
      }
      minLeadTime
      leadTime10
      leadTime25
      leadTime50
      leadTime100
      leadTime200
      shipDateOverride
      shipDateDisclaimer
      lang
      maxLeadTime
      productFinish {
        section
        finish {
          ... on SanityFinish {
            id
            title
            image {
              asset {
                url
                _id
              }
              altText
            }
            code
            category {
              id
              title
            }
          }
        }
        leadTimeDays
      }
      productOptionList
      visualAttributes
      related {
        description
        subtitle
        title
        featuredProducts {
          ... on SanityProduct {
            id
            title
            partial_sku
            slug {
              current
            }
            slugPrefix {
              current
            }
            price
            downloads {
              drawings
              revit
              sketchUp
              ies
              techPack
              installInstructions
              other {
                title
                link
              }
              other2 {
                title
                link
              }
            }
            photometrySummary
            isNew
            _type
          }
          ... on SanityProductBasic {
            title
            slug {
              current
            }
            price
            isNew
            id
            _type
            image {
              asset {
                _id
              }
            }
          }
        }
      }
    }
  }
`;

const SkuTemplate = ({
  data: { sanityProduct, sanitySku, sanityConfigurator },
  pageContext: { id, slugPrefix, skuSuffix, skuId, _rawComponents },
  location,
}) => {
  const {
    productOptionList,
    minLeadTime,
    shipDateOverride,
    shipDateDisclaimer,
    maxLeadTime,
    leadTime10,
    leadTime25,
    leadTime50,
    leadTime100,
    leadTime200,
    productType,
    title,
    collection,
    configurationReminder,
    partial_sku,
    visualAttributes,
    shoppable,
    lang,
    metaDescription,
    hasOffImage,
    productImagesCarousel,
    installCarousel,
    productFinish,
  } = sanityProduct;

  const { price, discount_price, weight } = sanitySku;

  const jsonLang = JSON.parse(lang);
  const options = JSON.parse(sanitySku.options);

  const { authUser } = useContext(AuthUserContext);
  const isBrowser = typeof window !== 'undefined';

  const [quantity, setQuantity] = useState(1);
  const [productAdded, setProductAdded] = useState(false);
  const [addedLeadTimeDays, setAddedLeadTimeDays] = useState(0);
  const [calculatedMinLeadTime, setCalcMinLeadTime] = useState(minLeadTime);
  const [calculatedMaxLeadTime, setCalcMaxLeadTime] = useState(maxLeadTime);

  useEffect(() => {
    if (productOptionList) {
      viewItemDL({
        price,
        productCode: `${partial_sku}-${skuSuffix}`,
        name: title,
        variant: productType[0]?.title,
        quantity: 1,
        skuSuffix
      })
    }
    
  }, [productOptionList])

  const [imageSrc] = useState(
    productImageSrc(
      slugPrefix.current,
      'pdp',
      partial_sku,
      attributesFromSkuSuffix(skuSuffix, visualAttributes),
    ),
  );
  const [custom, setCustom] = useState(false);

  const skuSuffixArr = skuSuffix.split('-');

  function calculateAddedLeadTime() {
    const maxDays = productFinish.reduce((max, e) => {
      if (skuSuffix.includes(e.finish.code)) {
        return Math.max(max, e.leadTimeDays ?? 0);
      }
      return max;
    }, 0); // Initialize with 0
  
    setAddedLeadTimeDays(maxDays);
  }

  function updateUrl(code, key, offset, modalContainerScroll) {
    const updatedSku = [...skuSuffixArr];
    updatedSku[key] = code;
    const state = {
      scrollTo: offset ?? window.pageYOffset,
    };
    // check if modalContainerScroll is a number rather than truthy so that 0 is accepted
    if (typeof modalContainerScroll === 'number') {
      state.modal = {
        open: true,
        index: key,
        modalContainerScroll,
      };
    }
    navigate(`/${slugPrefix.current}/${updatedSku.join('-').toLowerCase()}`, {
      state,
    });
  }

  const isCustom = useCustomFinishCheck(skuSuffix);

  useEffect(() => {
    setCustom(isCustom);
    if (isBrowser && location.state?.scrollTo > 0) {
      window.scrollTo(0, location.state.scrollTo);
      if (location.state.modal) {
        noScroll.on();
      }
    }

    calculateAddedLeadTime();
  }, [skuSuffix]);

  const productDescription = [];
  skuSuffixArr.forEach((attribute, index) => {
    productDescription.push(productOptionList[index] || `Option ${index}`);
    productDescription.push(jsonLang[attribute]);
  });

  //Add shipping data to cart item
  productDescription.push('Estimated Ship Date');
  productDescription.push('');

  const productDescriptionObj = [];
  skuSuffixArr.forEach((attribute, index) => {
    productDescriptionObj.push({
      attr: productOptionList[index],
      value: jsonLang[attribute],
    });
  });

  const reducer = (accum, currentVal, index) => {
    const updatedAccum = accum;
    updatedAccum[`data-item-custom${index}-name`] = currentVal;
    return updatedAccum;
  };

  const buyAttr = productDescription.reduce(reducer, {});

  const leadTimeAttrObject = {
    minLeadTime,
    maxLeadTime,
    leadTime10,
    leadTime25,
    leadTime50,
    leadTime100,
    leadTime200,
    shipDateOverride,
    addedLeadTimeDays,
  };

  const leadTimeAttrs = Object.keys(leadTimeAttrObject).reduce(
    (accum, currentVal, index) => {
      const updatedAccum = accum;
      updatedAccum[`data-item-custom10${index + 2}-name`] = currentVal;
      updatedAccum[`data-item-custom10${index + 2}-value`] =
        leadTimeAttrObject[currentVal];
      updatedAccum[`data-item-custom10${index + 2}-type`] = 'hidden';

      return updatedAccum;
    },
    {},
  );

  // for project purposes
  const projectJson = `
  {
    "name": "${title}",
    "category": "${productType[0].title}",
    "image": "${imageSrc}",
    "description": ${JSON.stringify({ ...productDescriptionObj })},
    "sku": "${partial_sku}-${skuSuffix}",
    "price": ${price && price / 100},
    "discount_price": ${discount_price && discount_price / 100},
    "weight": ${weight},
    "minLeadTime": ${minLeadTime},
    "maxLeadTime": ${maxLeadTime},
    "leadTime10": ${leadTime10},
    "leadTime25": ${leadTime25},
    "leadTime50": ${leadTime50},
    "leadTime100": ${leadTime100},
    "leadTime200": ${leadTime200},
    "addedLeadTimeDays": ${addedLeadTimeDays},
    "shipDateOverride": ${JSON.stringify(shipDateOverride)}
  }
  `;
  // for seo purposes
  const projectJsonSEO = `
    {
      "@context": "https://www.schema.org",
      "@type": "product",
      "brand": {
        "@type": "Brand",
        "name": "RBW"
      },
      "name": "${title}",
      "category": "${productType[0].title}",
      "image": "${imageSrc}",
      "description": "${metaDescription}",
      "sku": "${partial_sku}-${skuSuffix}",
      "logo": "https://rbw.com/images/rbw_logo.svg",
      "offers": {
        "@type": "Offer",
        "priceCurrency": "USD",
        "price": "${price && price / 100}",
        "url": "${`https://rbw.com/${
          slugPrefix.current
        }/${skuSuffix.toLowerCase()}`}"
      },
      "url": "${`https://rbw.com/${
        slugPrefix.current
      }/${skuSuffix.toLowerCase()}`}"
    }
    `;

  const handleQuantityChange = (val) => {
    let q = parseInt(val, 10);
    setQuantity(q);

    let leadTimeRange = calcLeadTimeByQuantity(Number.isNaN(q) ? 0 : q, {
      minLeadTime,
      shipDateOverride,
      shipDateDisclaimer,
      maxLeadTime,
      leadTime10,
      leadTime25,
      leadTime50,
      leadTime100,
      leadTime200,
    });

    setCalcMinLeadTime(leadTimeRange.min);
    setCalcMaxLeadTime(leadTimeRange.max);
  };

  const handleProductAdd = () => {
    setProductAdded(true);
    setTimeout(() => {
      setProductAdded(false);
    }, 1500);
  }

  const renderConfigurator = (sliceOption) => {
    return (
      <Configurator
        options={
          sliceOption && options?.length > 2 ? options.slice(0, 2) : options
        }
        customPrice={custom}
        configurationReminder={configurationReminder}
        lang={jsonLang}
        productOptionList={
          sliceOption && productOptionList?.length > 2
            ? productOptionList.slice(0, 2)
            : productOptionList
        }
        slug={slugPrefix.current}
        image={imageSrc}
        title={title}
        scrollToState={location?.state?.scrollTo}
        modalState={location?.state?.modal || null}
        productCode={`${partial_sku}-${skuSuffix}`}
        blankProductCode={`${partial_sku}-${blankSkuSuffixHtml(skuSuffix)}`}
        hasOffImage={!!hasOffImage}
        productImagesCarousel={productImagesCarousel}
        productFinishes={productFinish}
      >
        <div className="xl:flex print:hidden mb-10">
          <Quantity
            minLeadTime={calculatedMinLeadTime}
            maxLeadTime={calculatedMaxLeadTime}
            addedLeadTimeDays={addedLeadTimeDays}
            shipDateOverride={shipDateOverride}
            shipDateDisclaimer={shipDateDisclaimer}
            quantity={quantity}
          >
            <input
              type="number"
              className="bg-mono-100 w-16 p-2 pl-6"
              value={quantity}
              min="0"
              max="500"
              onChange={(event) => {
                handleQuantityChange(event.target.value);
              }}
            />
          </Quantity>
          {!custom ? (
            <Price price={price} discount_price={discount_price} />
          ) : (
            <div className="xl:w-1/2 flex justify-between xl:justify-end mb-10">
              <div className="text-right">
                <p className="type-sans-430">Price available on request</p>
              </div>
            </div>
          )}
        </div>
        <ul className="print:hidden">
          <li className="mb-2">
            <CreateProject
              sku={`${partial_sku}-${skuSuffix}`}
              quantity={quantity}
              slug={`/${slugPrefix.current}/${skuSuffix.toLowerCase()}`}
            />
          </li>
          {shoppable && !custom && price && (
            <li>
              <Button
                aria-label="Buy"
                className={cn(
                  'hidden btn-default btn-black btn-lg btn-interaction btn-interaction--black w-full type-upper-240 snipcart-add-item',
                  {
                    'is-loading': productAdded,
                    btn:
                      !authUser ||
                      !authUser?.emailVerified ||
                      !authUser?.user_trade,
                  },
                )}
                disabled={
                  authUser?.emailVerified === true &&
                  authUser?.user_trade === true
                }
                onClick={() => handleProductAdd()}
                data-item-description={`${partial_sku}-${skuSuffix}`}
                data-item-id={`${partial_sku}-${skuSuffix}`}
                data-item-image={imageSrc}
                data-item-name={title}
                data-item-price={price / 100}
                data-item-quantity={quantity}
                data-item-stackable="always"
                data-item-url={
                  process.env.GATSBY_DEVELOPMENT_MODE === 'true'
                    ? `https://rbw-qa.netlify.app/${
                        slugPrefix.current
                      }/${skuSuffix.toLowerCase()}`
                    : `/${slugPrefix.current}/${skuSuffix.toLowerCase()}`
                }
                data-item-weight={weight}
                data-item-custom100-name="Actual Price"
                data-item-custom100-value={price / 100}
                data-item-custom100-type="hidden"
                data-item-custom101-name="isUserTrade"
                data-item-custom101-value={!!authUser?.user_trade}
                data-item-custom101-type="hidden"
                {...leadTimeAttrs}
                {...buyAttr}
              >
                <span>Buy</span>
                <span>Added to cart</span>
              </Button>
              <Button
                aria-label="Buy"
                className={cn(
                  'hidden btn-default btn-black btn-lg btn-interaction btn-interaction--black w-full type-upper-240 snipcart-add-item',
                  {
                    'is-loading': productAdded,
                    btn: !!authUser?.emailVerified && !!authUser?.user_trade,
                  },
                )}
                onClick={() => handleProductAdd()}
                disabled={
                  authUser === null ||
                  authUser?.emailVerified === false ||
                  authUser?.user_trade === false
                }
                data-item-description={`${partial_sku}-${skuSuffix}`}
                data-item-id={`${partial_sku}-${skuSuffix}-discount`}
                data-item-image={imageSrc}
                data-item-name={title}
                data-item-price={discount_price / 100}
                data-item-quantity={quantity}
                data-item-stackable="always"
                data-item-url={
                  process.env.GATSBY_DEVELOPMENT_MODE === 'true'
                    ? `https://rbw-qa.netlify.app/${
                        slugPrefix.current
                      }/${skuSuffix.toLowerCase()}`
                    : `/${slugPrefix.current}/${skuSuffix.toLowerCase()}`
                }
                data-item-weight={weight}
                data-item-custom100-name="Actual Price"
                data-item-custom100-value={price / 100}
                data-item-custom100-type="hidden"
                data-item-custom101-name="isUserTrade"
                data-item-custom101-value={!!authUser?.user_trade}
                data-item-custom101-type="hidden"
                {...leadTimeAttrs}
                {...buyAttr}
              >
                <span>Buy</span>
                <span>Added to cart</span>
              </Button>
            </li>
          )}
        </ul>
      </Configurator>
    );
  };

  return (
    <div className="flex flex-col md-down:overflow-hidden print:overflow-visible print:block">
      <SEO
        title={title}
        image={imageSrc || null}
        description={metaDescription || null}
      >
        <script type="application/ld+json" id="js-project-card">
          {projectJson}
        </script>
        <script type="application/ld+json">{projectJsonSEO}</script>
        <link rel="preload" as="image" href={imageSrc || null} />
      </SEO>

      <div className="wrapper print:order-2">
        <div className="row top-0 pointer-events-none relative z-20 md:-mt-5 print:hidden">
          <div className="col-12 md:col-7">
            <ProductDisplayHeading>
              <div className="type-sans-030 text-mono-500">
                <Link
                  to="/type"
                  underline={false}
                  internalExternal={false}
                  aria-label="Products"
                  className="after:h-[75%]"
                >
                  Products
                </Link>
                {` / `}
                <Link
                  to={{ page: productType[0] }}
                  underline={false}
                  internalExternal
                  aria-label={productType[0].title}
                  className="after:h-[75%]"
                >
                  {productType[0].title}
                </Link>
                {` / ${title}`}
              </div>
              <h1 className="type-sans-830 lg:type-sans-930 mb-2 pr-2 md:pr-6">
                {title}
              </h1>
              <div className="type-sans-030 text-black h-12">
                <div>
                  {collection?.length > 0 ? (
                    <Link
                      to={{ page: collection[0] }}
                      internalExternal
                      aria-label={`Explore ${collection[0].heroPrimary.title} Collection`}
                    >
                      Explore {`${collection[0].heroPrimary.title} Collection`}
                    </Link>
                  ) : null}
                </div>
              </div>
            </ProductDisplayHeading>
          </div>
        </div>

        <ProductPrint
          sanityProduct={sanityProduct}
          productCode={`${partial_sku}-${skuSuffix}`}
          blankProductCode={`${partial_sku}-${blankSkuSuffixHtml(skuSuffix)}`}
          title={title}
          productType={productType[0].title}
          options={options}
          customPrice={custom}
          configurationReminder={configurationReminder}
          lang={jsonLang}
          productOptionList={productOptionList}
          slug={slugPrefix.current}
          image={imageSrc}
          title={title}
          scrollToState={location?.state?.scrollTo}
          modalState={location?.state?.modal || null}
          productCode={`${partial_sku}-${skuSuffix}`}
          blankProductCode={`${partial_sku}-${blankSkuSuffixHtml(skuSuffix)}`}
          hasOffImage={!!hasOffImage}
          productImagesCarousel={productImagesCarousel}
          productFinishes={productFinish}
        >
          {renderConfigurator()}
          <div className="relative">
            <TearSheetDrawings
              technicalDrawing={sanityProduct.technicalDrawing}
            />
          </div>
          <div className="relative mb-8">
            <img className="" src={imageSrc} alt="" />
          </div>
        </ProductPrint>
        <div className="row print:hidden">
          <div className="col-12 -mt-12 print:mt-0">
            <div className="print-page">
              <PrintHeading title={title} productType={productType[0].title} />
              <TearSheetCover>
                <img className="pb-8" src={imageSrc} alt="" />
                {custom && (
                  <div className={skuStyles.customWrapper}>
                    <span>Contact sales for custom finish</span>
                  </div>
                )}
                {configurationReminder && (
                  <div className={skuStyles.configurationReminderWrapper}>
                    <PortableText
                      className={skuStyles.configurationReminder}
                      content={configurationReminder}
                    />
                  </div>
                )}
              </TearSheetCover>
            </div>
            <div
              className="print-page hidden print:block"
              style={{ pageBreakBefore: 'always' }}
            >
              <ProductDisplay sanityProduct={sanityProduct} />
            </div>
            <div className="print-page">
              <SkuProvider value={updateUrl}>
                {renderConfigurator()}
              </SkuProvider>
            </div>
            <div className="print-page">
              <TearSheetDrawings
                technicalDrawing={sanityProduct.technicalDrawing}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-col-reverse gap-24 mb-0 md:mb-36">
        {installCarousel?.length ? (
          <div className="print:hidden">
            <ImageCenterCarousel images={installCarousel} />
          </div>
        ) : null}

        <div className="wrapper overflow-hidden print:hidden">
          <div className="row avoid-break border border-white">
            <PrintHeading title={title} productType={productType[0].title} />
            <div className="col-12">
              <ProductDisplay sanityProduct={sanityProduct} />
            </div>
          </div>
        </div>
      </div>
      {_rawComponents &&
        _rawComponents.map((element) => (
          <div className="py-12 md:py-20 print:hidden" key={element._key}>
            {React.createElement(Components(element._type), {
              key: element._key,
              data: element,
            })}
          </div>
        ))}
      <div className="mb-12 md:mb-32 print:hidden">
        {sanityProduct.related && (
          <ProductCarousel data={sanityProduct.related} />
        )}
      </div>
    </div>
  );
};

SkuTemplate.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
    skuSuffix: PropTypes.string.isRequired,
    slugPrefix: PropTypes.shape({
      current: PropTypes.string.isRequired,
    }),
    skuId: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    sanitySku: PropTypes.shape({
      price: PropTypes.number,
      discount_price: PropTypes.number,
      weight: PropTypes.number.isRequired,
      options: PropTypes.string.isRequired,
    }),
    sanityProduct: PropTypes.shape({
      collection: PropTypes.array,
      configurationReminder: PropTypes.array,
      lang: PropTypes.string.isRequired,
      maxLeadTime: PropTypes.number.isRequired,
      minLeadTime: PropTypes.number.isRequired,
      partial_sku: PropTypes.string.isRequired,
      productOptionList: PropTypes.array.isRequired,
      metaDescription: PropTypes.string,
      productType: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.shape({
            current: PropTypes.string.isRequired,
          }),
          title: PropTypes.string.isRequired,
        }),
      ),
      related: PropTypes.object,
      shoppable: PropTypes.bool,
      technicalDrawing: PropTypes.array,
      title: PropTypes.string.isRequired,
      visualAttributes: PropTypes.number,
    }),
  }),
  location: PropTypes.shape({
    hash: PropTypes.string,
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
    protocol: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
};

SkuTemplate.defaultProps = {
  pageContext: {
    slugPrefix: {},
  },
  data: {
    sanitySku: {
      price: null,
      discount_price: null,
    },
    sanityProduct: {
      shoppable: false,
      visualAttributes: 0,
      metaDescription: '',
    },
    allSanitySku: {},
  },
  location: PropTypes.shape({
    hash: '',
    host: '',
    hostname: '',
    href: '',
    key: '',
    origin: '',
    pathname: '',
    port: '',
    protocol: '',
    search: '',
    state: '',
  }),
};

export default SkuTemplate;
