import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { printProduct } from '@helpers/printProduct';
import copy from 'copy-to-clipboard';
import cn from 'classnames';

import useIsomorphicLayoutEffect from '@utility/useIsomorphicLayoutEffect';
import Button from '@components/Button';
import * as styles from './styles';

const Controls = () => {
  const [windowURL, setWindowURL] = useState('');
  const [copyText, setCopyText] = useState(false);

  useIsomorphicLayoutEffect(() => {
    setWindowURL(window.location.href);
  }, [setWindowURL]);

  const copyUrl = () => {
    copy(window.location.href);
    setCopyText(true);
    setTimeout(() => {
      setCopyText(false);
    }, 1000);
  };
  return (
    <StaticQuery
      query={graphql`
        query ConfiguratorQuery {
          allSanityConfigurator {
            edges {
              node {
                shareMessage
              }
            }
          }
        }
      `}
      render={(data) => {
        const {
          allSanityConfigurator: { edges },
        } = data;
        let message = null;

        if (edges.length) {
          message = edges[0].node.shareMessage;
        }

        return (
          <ul className={styles.controls} id='__productControls'>
            <li className={`hidden xl:block ${styles.btnItem}`}>
              <Button
                icon="sheet"
                title="Click to view a digital tear sheet of your configured product."
                className={`btn btn-secondary ${styles.btn}`}
                onClick={() => {
                  printProduct();
                }}
                aria-label="Generate Tear Sheet"
              >
                Generate Tear Sheet
              </Button>
            </li>
            <li className={`${styles.btnItem} w-32`}>
              <Button
                className={cn(
                  `btn btn-secondary btn-interaction btn-interaction--white ${styles.btn}`,
                  {
                    'is-loading': copyText,
                  },
                )}
                aria-label="Copy Url"
                onClick={() => copyUrl()}
              >
                <span className={styles.btnCopied}>
                  <span>Copy Url</span>
                  <span
                    className="btn-icon"
                    style={{
                      backgroundImage: "url('/images/icons/icon-copy-url.svg')",
                    }}
                  />
                </span>
                <span className={styles.btnCopied}>Copied!</span>
              </Button>
            </li>
            <li className={styles.btnItem}>
              <a
                href={encodeURI(
                  `mailto:?subject=Subject Line&body=${message}\n\n${windowURL}`,
                )}
                className={`btn btn-secondary ${styles.btn} ${styles.share}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="my-auto">Share</div>
                <span
                  className="btn-icon btn-icon--right"
                  style={{
                    backgroundImage: `url('/images/icons/icon-arrow-up-right-m.svg')`,
                  }}
                />
              </a>
            </li>
          </ul>
        );
      }}
    />
  );
};

export default Controls;
